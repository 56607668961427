import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/templates/MDXDocs.js";
import { Divider } from 'fannypack';
import ColouredText from '../../../components/worksafe-vic/ColouredText';
import Highlight from '../../../components/worksafe-vic/Highlight';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2 {...{
      "id": "eftpos-attributes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#eftpos-attributes",
        "aria-label": "eftpos attributes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`EFTPOS attributes`}</h2>
    <p>{`Partners can submit Tyro EFTPOS card requests via the Transaction SDK and obtain transaction status changes via both callbacks and webhooks.`}</p>
    <p><strong parentName="p">{`Creating a transaction`}</strong></p>
    <p><strong parentName="p">{`Use function`}</strong>{`:`}<Highlight mdxType="Highlight">{` medipassTransactionSDK.renderCreateTransaction() `}</Highlight></p>
    <p>{`To process a Tyro EFTPOS purchase request the following fields apply. Any required field not passed to the SDK will be prompted within the Tyro Health submission page.`}</p>
    <h3 {...{
      "id": "eftpos-submission-attributes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#eftpos-submission-attributes",
        "aria-label": "eftpos submission attributes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><strong parentName="h3">{`EFTPOS submission attributes`}</strong></h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Field`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Type / Format`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Condition`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Description`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Example`}</strong></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`platform`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`String`}</strong>{` `}<br />{`Valid values only`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><br />{`The SDK supports two modes - one for payment transactions and another for claims.  Set to: - `}<strong parentName="td">{`virtual-terminal`}</strong>{` for card payments`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{` virtual-terminal`}</Highlight></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`paymentMethod`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`String`}</strong>{` `}<br />{`Valid values only`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`O`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Valid values only. `}<br />{`  For EFTPOS payments only, specify a method:`}<br />{`- Set to `}<strong parentName="td">{`terminal`}</strong>{` for Tyro EFTPOS`}<br />{`- Set to `}<strong parentName="td">{`new-payment-card`}</strong>{` for provider entry`}<br />{`- Set to `}<strong parentName="td">{`mobile`}</strong>{` for SMS payment requests`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{` terminal`}</Highlight></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`chargeAmount`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`String`}</strong>{` `}<br />{`Currency notation `}<br />{` Up to 6 numeric and 2 decimal digits`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`For EFTPOS payments only, specify a total tender amount. Gross (including GST) amount in $XX.XX format.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{` $15.15`}</Highlight></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`providerNumber`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`String`}</strong>{` `}<br />{`Up to 16 alphanumeric characters`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`O`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><br />{`Servicing provider number or other registration identifier which must be valid and active in Medipass and HealthPoint for a given funder. For PHI quotes and claims, a Medicare issued provider number should be used where available. Where a Medicare provider number is not applicable, use the appropriate identifier - a Medibank, Fund specific or HealthPoint issued provider number. The provider number can also be used to set the default terminal identifier (TID) and merchant identifier (MID) if multiple TIDs and MIDs exist for a business.Optional for EFTPOS payments.Up to 16 alphanumeric characters`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{`0034503W`}</Highlight></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`invoiceReference`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`String`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`O`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Provider nominated unique transaction reference number, such as an invoice number from the practice management or accounting system. Used for reconciliation purposes.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td"><strong parentName="em"><inlineCode parentName="strong">{`Patient `}</inlineCode></strong></em></td>
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td"><strong parentName="em"><inlineCode parentName="strong">{` object`}</inlineCode></strong></em></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`patient.firstName`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`String`}</strong>{` `}<br />{` Up to 40 characters`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`O`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Patient first name as on their health fund card`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{`John`}</Highlight></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`patient.lastName`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`String`}</strong>{` `}<br />{`Up to 40 characters`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`O`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Patient last name as on their health fund card`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{`Curtin`}</Highlight></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`patient.dob`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`String`}</strong>{` `}<br />{` YYYY-MM-DD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`O`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Patient Date of Birth in `}{`'`}{`YYYY-MM-DD`}{`'`}{` format.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{`1967-11-02`}</Highlight></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`patient.mobile`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`String`}</strong>{` `}<br /></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`O`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Optional mobile phone number of the member. This can be used for patient searches in the Medipass portalInternational or national notation supported.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{`+61411111111 or 0411111111`}</Highlight></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`patient.refId`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`String`}</strong>{` `}<br />{` Up to 128 alphanumeric characters`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`O`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`An optional but recommended patient unique reference as set by you, such as a UUID. This value is used to uniquely identify a patient inMedipass. Omitting refId will cause duplicate patient records to be created and could make patient searches more difficult for providers who also use the Medipass portal to check on status of a claim and remittance.If supplied and valid, this value will pre-populate patient details from the patient record in the business as set in any prior claim. Any patient details sent for an existing patient record will update those values.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{`5AD5E2AF-69D2-43D3-9321-428495205E5E `}</Highlight></td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "eftpos-response-attributes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#eftpos-response-attributes",
        "aria-label": "eftpos response attributes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`EFTPOS response attributes`}</h3>
    <p>{`When a transaction is processed, the SDK will pass through the transaction details to the first parameter of the onSuccess callback.`}</p>
    <p>{`The response payload contains a number of attributes with a complete description of the transaction model in our online API documentation.`}</p>
    <p><strong parentName="p">{`Key response attributes include`}</strong></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Field`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Type / Format`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Description`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Example`}</strong></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`_`}{`id`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`String`}</strong>{` `}<br />{` 64-128 bit ID`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`a unique transaction level identifier`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{` 12BA46C3-BA6B-4192-9368-BD78AFAAB489 `}</Highlight></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`status`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`String`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Overall transaction status`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{` Approved `}</Highlight></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`payments[].status`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`String`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Status of the payment (if run in conjunction with a claim)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{` Approved `}</Highlight></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`payments[].amount`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`String`}</strong>{` `}<br />{` Currency notation`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Amount paid`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{` $40.00 `}</Highlight></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`payments[].gatewayDescription`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`String`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`For declined transaction, additional descriptions from the payment network`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{` 2001 - Insufficient funds `}</Highlight></td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      